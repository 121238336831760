import { createRef } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import SearchInput from '@nerdwallet/nw-search-input';
import classNames from 'classnames';
import config from '@nerdwallet/app-config';
import { getLogger } from '@nerdwallet/logging';
import { useStore } from '@nanostores/preact';

import { trackElementInteraction } from '../lib/tracking';
import MagnifyingGlass from '../auth/svg/magnifying-glass';
import nwApiSdk from '../lib/nw-api-sdk';
import CloseButton from '../auth/buttons/CloseButton';
import {
  $openedMenu,
  $onCloseMenuCallback,
  $searchLoaded,
  $rightButtonsReady,
} from '../store/client';

import styles from './Search.module.less';
import ErrorBoundary from '../lib/ErrorBoundary';

interface Props {
  includeSearch?: boolean;
}

const Search: React.FC<Props> = ({ includeSearch = true }) => {
  const logger = getLogger('right-buttons');
  logger.info('Search island is loading...');

  const [checkboxInputChecked, setCheckboxInputChecked] = useState(false);
  const checkboxInputRef = createRef();
  const rightButtonsReady = useStore($rightButtonsReady);

  // Sets $searchLoaded when this component is ready to render client-side
  useEffect(() => {
    $searchLoaded.set(true);
  }, []);

  const trackSearchOpen = (type: string, event) => {
    if (!event.target.checked) {
      return;
    }
    trackElementInteraction.bedrock({
      entityName: 'global_header_search_button',
      action: 'open',
      interactionType: type.toLowerCase(),
    });
  };

  const onCheckboxClick = (event) => {
    try {
      trackSearchOpen('press', event);
      checkboxInputRef.current.checked = true;
      setCheckboxInputChecked(checkboxInputRef.current.checked);
      $openedMenu.set('search');
    } catch (err) {
      logger.debug('Search click is not working', err);
    }
  };

  const closeButtonClick = () => {
    const checkbox = checkboxInputRef.current;
    if (!checkbox) return;
    checkbox.checked = false;
    setCheckboxInputChecked(false);
    $openedMenu.set('');
  };

  useEffect(() => {
    $onCloseMenuCallback.set(closeButtonClick);

    return () => {
      $onCloseMenuCallback.set(null);
    };
  }, [closeButtonClick]);

  const handleSearch = (
    query: string,
    { suggestion }: { suggestion?: boolean } = {}
  ) => {
    const url = `/search/results?q=${encodeURIComponent(query)}${
      suggestion ? '&s=1' : ''
    }`;
    window.location.href = url;
  };

  const placeholder = 'Try "Best savings account?"';

  if (!includeSearch) {
    return null;
  }

  return (
    <ErrorBoundary>
      {rightButtonsReady && (
        <div className={styles.searchWrapper}>
          <input
            type="checkbox"
            className={styles.searchButtonCheckbox}
            id="searchButtonCheckbox"
            name="searchButtonCheckbox"
            onClick={(event) => onCheckboxClick(event)}
            ref={checkboxInputRef}
          />
          <label
            for="searchButtonCheckbox"
            className={styles.searchButton}
            aria-label="Search"
          >
            <MagnifyingGlass width={20} height={20} />
          </label>
          {checkboxInputChecked ? (
            <div className={classNames(styles.searchDropdown)}>
              <div className={styles.searchFormContainer}>
                <SearchInput
                  nwApiSdk={nwApiSdk}
                  onSearch={handleSearch}
                  className={styles.searchInput}
                  searchServicesBaseUri={
                    config.API.BASE_URLS.SEARCH ||
                    'https://www.nerdwallet.com/api/search'
                  }
                  value=""
                  showClearInput={true}
                  inputClearColor="neutral-dark"
                  autoFocus
                  placeholder={placeholder}
                  inputProps={{
                    'aria-label': 'Search the NerdWallet site',
                  }}
                />
              </div>

              <CloseButton onClick={closeButtonClick} />
            </div>
          ) : (
            checkboxInputRef?.current
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default Search;
